import { useCallback, useEffect, useState } from "react";
import { useQuery } from "../base";
import globalState, { GlobalState } from "../../globalState";
import { useIntl } from "react-intl";
import hookMessages from "../messages";
import { fetchAllNotifications, NotificationWithClientData } from "src/api/notificationApi";
const LOAD_MORE_LIMIT = 10;
const START_LIMIT = 30;

function useFetchAllNotifications({ userId }: { userId: string | undefined;}) {
  const { formatMessage } = useIntl();
  const [allNotifications, setAllNotifications] = useState<NotificationWithClientData[]>([]);
  const [lastId, setLastId] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const fetchAllNotificationsQuery = useCallback(async () => {
    try {
      if (!userId) return [];
      const newDocs = await fetchAllNotifications({ userId, limit: START_LIMIT });
      
      if (newDocs.length === 0) {
        setHasMore(false);
        return [];
      }

      setLastId(newDocs[newDocs.length - 1].id);
      setAllNotifications(newDocs);
      setIsLoading(false);
      return newDocs;
    } catch (error) {
      globalState.update((state: GlobalState) => {
        state.toastQueue.push({
          message: formatMessage(hookMessages.error_loading_notifications),
          severity: "error",
        });
      });
    }
  }, [userId]);

  const fetchMoreNotifications = useCallback(async () => {
    if (!userId || !lastId || !hasMore) return;
    setIsLoadingMore(true);
    try {
      const newDocs = await fetchAllNotifications({ userId, limit: LOAD_MORE_LIMIT, startAfter: lastId });
      if (newDocs.length === 0) {
        setHasMore(false);
        return;
      }
      if(newDocs.length < LOAD_MORE_LIMIT) {
        setHasMore(false);
      }

      setLastId(newDocs[newDocs.length - 1].id);
      setAllNotifications((prev) => [...prev, ...newDocs]);
      setIsLoadingMore(false);
    } catch (error) {
      globalState.update((state: GlobalState) => {
        state.toastQueue.push({
          message: formatMessage(hookMessages.error_loading_notifications),
          severity: "error",
        });
      });
    }
  }, [userId, lastId, hasMore]);

  const updateReadNotificationsList = (notificationIds: Set<string>) => {
    setAllNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id && notificationIds.has(notification.id) ? { ...notification, readTrainer: true } : notification
      )
    );
  }

  const updateUnlinkNotification = (notificationId: string, type: 'accepted'|'rejected') => {
    setAllNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id && notification.id === notificationId ? { ...notification, accepted: type === 'accepted', rejected: type === 'rejected' } : notification
      )
    );
  }

  const { data, status, error } = useQuery(fetchAllNotificationsQuery);

  useEffect(() => {
    if(userId){
      fetchAllNotificationsQuery();
    }
  }, [userId]);


  return {
    notifications: allNotifications,
    status,
    error,
    isLoading,
    isLoadingMore,
    lastId,
    hasMore,
    fetchMoreNotifications,
    updateReadNotificationsList,
    updateUnlinkNotification,
    fetchAllNotificationsQuery
  };
}

export default useFetchAllNotifications;
