import { Invitation, User } from "myfitworld-model";
import { firestore } from "../firebase";
import firebase from "firebase";
export enum PendingClientStatus{
    "pending",
    "rejected",
    "error",
}
export const getInvitation = async (email: string) => {

    try {
        const invitationDocs = await firestore.collection('invitations')
        .where('email', '==', email)
        .where('acceptedOn', '==', null)
        .where('rejected', '==', false)
        .get();
        if(invitationDocs.docs.length > 0) {
            return {
                ...invitationDocs.docs[0].data(),
                id: invitationDocs.docs[0].id 
              } as Invitation;
              
        }

        return undefined;
    } catch (error) {
        console.log(error);
      throw error;
    }
};

export const acceptInvitation = async (invitation: Invitation, userId: string) => {
    try {
        const invitationDoc = firestore.collection('invitations').doc(invitation.id);
        const nowTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
        invitationDoc.update({
            acceptedOn: nowTimestamp
        });

        const userRef = firestore.collection("users").doc(userId);
        const newOrganization = {id: invitation.organization, role: invitation.role, archived: false};
        const doc = await userRef.get();
        const user = doc.data();
        let organizations = user?.organizations;
        let oldOrganization = organizations[organizations.length - 1];
        oldOrganization.archived = true;
        organizations.push(newOrganization);
        await userRef.update({
            currentOrganization: invitation.organization,
            organizations: organizations,
            hasInvitation: false
        });
        const orgUserRef = firestore.collection("organizationUser").doc(`${userId}-${invitation.organization}`);
        await orgUserRef.update({
            invitationAccepted: true
        });
        if(invitation.role === 'Client'){
            await userRef.update({
                trainers: firebase.firestore.FieldValue.arrayUnion(invitation.invitedBy)
            });
        }

    } catch (error) {
      throw error;
    }
}   

export const rejectInvitation = async (id: string, userId: string) => {
    try {
        const invitationDoc = firestore.collection('invitations').doc(id);
        invitationDoc.update({
            rejected: true
        });
        const userRef = firestore.collection("users").doc(userId);
        await userRef.update({
            hasInvitation: false
        });
    } catch (error) {
      throw error;
    }
} 

export const getPendingClientStatus = async (invitationId: string,userId:string, userEmail:string ) => {
    try {
        const invitationDocSnapshot = await firestore.collection('invitations').doc(invitationId).get();
        let userDocSnapshot;

        if (userId !== "") {
            userDocSnapshot = await firestore.collection("users").doc(userId).get();
        } else {
            const userQuerySnapshot = await firestore.collection("users").where("email", "==", userEmail).get();

            if (userQuerySnapshot.empty) {
                return PendingClientStatus.error;
            }
            userDocSnapshot = userQuerySnapshot.docs[0]; // Take the first document
        }
        

        if (!userDocSnapshot.exists || !invitationDocSnapshot.exists) {
         return PendingClientStatus.error;
        }

        const userDoc: User = userDocSnapshot.data() as User;
        const invitationDoc : Invitation = invitationDocSnapshot.data() as Invitation;


        if(userDoc.hasInvitation === undefined){
            return PendingClientStatus.error;
        } else if(invitationDoc.rejected === true){
            return PendingClientStatus.rejected;
        } else if(invitationDoc.rejected ===false){
            return PendingClientStatus.pending;
        } else return PendingClientStatus.error; // In case something else is missing from database. 

    } catch (error) {
        console.log('Error:',invitationId,  userId, error);
      throw error;
    }
} 
  