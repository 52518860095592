import React from 'react';
import WorkoutReaction from 'myfitworld-model/dist/enums/WorkoutReaction';
import {makeStyles} from "@material-ui/core";

const badReaction = require("../assets/images/bad-react.png");
const okReaction = require("../assets/images/ok-react.png");
const greatReaction = require("../assets/images/great-react.png");
const goodReaction = require("../assets/images/good-react.png");

const useStyles = makeStyles(() => ({
  image: {
    width: 24,
    height: 24,
  },
  smallSize: {
    width: 15,
    height: 15
  }
}));

const getReactionImage = (reaction: WorkoutReaction) => {
  switch (reaction) {
    case WorkoutReaction.GREAT:
      return greatReaction;
    case WorkoutReaction.OK:
      return okReaction;
    case WorkoutReaction.BAD:
      return badReaction;
    case WorkoutReaction.GOOD:
      return goodReaction;
  }
};

const ReactionImage = ({reaction, smallSize}: Props) => {
  const classes = useStyles();
  const image = getReactionImage(reaction);
  return (
    <img alt='Reaction' src={image} className={smallSize ? classes.smallSize : classes.image}/>
  );
};

export default ReactionImage;

interface Props {
  reaction: WorkoutReaction;
  smallSize?: boolean;
}

