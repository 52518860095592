import React, { Component, ErrorInfo, ReactNode } from 'react';
import { firestore } from '../firebase';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import globalState, { GlobalState } from '../globalState';

interface Props {
  children: ReactNode;
  componentName: string;
}

interface State {
  hasError: boolean;
  errorId?: string;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorId: undefined
    };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorLog = {
      componentName: this.props.componentName,
      error: {
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
      errorInfo: {
        componentStack: errorInfo.componentStack,
      },
      timestamp: new Date(),
      userAgent: navigator.userAgent,
      url: window.location.href,
    };

    firestore
      .collection('errorBoundariesWeb')
      .add(errorLog)
      .then(docRef => {
        this.setState({ errorId: docRef.id });
      })
      .catch(console.error);
  }

  handleCopyErrorId = () => {
    if (this.state.errorId) {
      navigator.clipboard.writeText(this.state.errorId).then(() => {
        globalState.update((state: GlobalState) => {
          state.toastQueue.push({
            message: "Error ID copied to clipboard",
            severity: "success",
          });
        });
      });
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box p={3}>
          <Alert
            severity="error"
            style={{ cursor: 'pointer' }}
            onClick={this.handleCopyErrorId}
          >
            <Typography variant="h6">
              Something went wrong...
            </Typography>
            {this.state.errorId && (
              <Typography>
                Click here to copy error ID: {this.state.errorId}
              </Typography>
            )}
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
