import React, {PropsWithChildren} from 'react';
import {Box, Button, Drawer, Typography, useMediaQuery} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from 'react-intl';
import globalMessages from '../messages';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { useDrawerStyles } from '../theme/useDrawerStyles';
import { TutorialInfoDialog } from 'src/pages/tutorials/TutorialInfoDialog';


export interface SideDrawerProperties {
  isOpen: boolean;
  onClose: () => void;
  title?:string;
  videoSource?: string;
}

export default function ({children, isOpen, onClose, title, videoSource}: PropsWithChildren<SideDrawerProperties>) {
  const isMobile = useMediaQuery('960px');
  const {mfwMuiWebTheme} = useMfwThemeProvider();
  const useStyles = useDrawerStyles(mfwMuiWebTheme, isMobile);
  const classes = useStyles();
  const {formatMessage} = useIntl();

  return (
    <Drawer
      classes={{paper: classes.drawerPaper}}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      <div className={classes.drawerHeader}>
        <Box display='flex' flexDirection='row'>
          {title && <Typography variant="h4" style={{marginBottom: 8}}>{title}</Typography>}
          {videoSource && <TutorialInfoDialog title={title ?? ""} videoSource={videoSource}/>}
        </Box>
        <Button
          size="small"
          variant="outlined"
          startIcon={<CloseIcon/>}
          onClick={_ => onClose()}
        >
          {formatMessage(globalMessages.close)}
        </Button>
      </div>
      {children}
    </Drawer>
  )
}
