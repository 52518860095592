import React, {useEffect, useState} from "react";
import {Activity, User} from "myfitworld-model";
import {Avatar, Box, makeStyles, Typography} from "@material-ui/core";
import usersApi from "../../api/usersApi";
import { Notifications } from "@material-ui/icons";
import {useMfwThemeProvider} from "myfitworld-utils";
import { useIntl } from "react-intl";
import chatMessages from './messages';
import { format } from "date-fns";
import { BaseNotificationView } from "../notifications/BaseNotificationView";

interface TrainerNotificationViewProps {
  activity: Activity;
  client?: User;
}

const TrainerNotificationView: React.FC<TrainerNotificationViewProps> = ({
  activity,
  client
}) => {
  const { formatMessage } = useIntl();

  return (
    <BaseNotificationView
      activity={activity}
      client={client}
      headerIcon={<Notifications color="primary" style={{width: 17, height: 17}}/>}
      headerLabel={formatMessage(chatMessages.trainer_notification).toUpperCase()}
    >
      <Typography>{activity.text}</Typography>
    </BaseNotificationView>
  );
};

export default TrainerNotificationView;

interface Props {
  activity: Activity;
  client?: User|undefined;
}
