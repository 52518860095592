import {  defineMessages } from "react-intl"

const messages = defineMessages({
    error_loading_program_information: {
        id: "messages.error_loading_program_information",
        defaultMessage: "Error loading program information"
    },
    error_loading_user_documents: {
        id: "messages.error_loading_user_documents",
        defaultMessage: "Error loading user documents"
    },
    error_loading_document_types: {
        id: "messages.error_loading_document_types",
        defaultMessage: "Error loading document types"
    },
    error_creating_document_types: {
        id: "messages.error_creating_document_types",
        defaultMessage: "Error creating document types"
    },
    error_deleting_document_types: {
        id: "messages.error_deleting_document_types",
        defaultMessage: "Error deleting document types"
    },
    open_form: {
        id: "open_form",
        defaultMessage: "You have a form open. Moving to a new page without saving will delete any changes. \n Do you wish to proceed?"
    },
    error_loading_more_workouts: {
        id: "messages.error_loading_more_workouts",
        defaultMessage: "Error loading more workouts"
    },
    error_loading_workouts: {
        id: "messages.error_loading_workouts",
        defaultMessage: "Error loading workouts"
    },
    error_updating_workout_date_and_time: {
        id: "messages.error_updating_workout_date_and_time",
        defaultMessage: "Error updating workout date and time"
    },
    error_updating_workout: {
        id: "messages.error_updating_workout",
        defaultMessage: "Error updating workout"
    },
    error_updating_workout_mark_as_done: {
        id: "messages.error_updating_workout_mark_as_done",
        defaultMessage: "Error updating workout mark as done"
    },
    error_deleting_workout: {
        id: "messages.error_deleting_workout",
        defaultMessage: "Error deleting workout"
    },
    error_creating_single_workout: {
        id: "messages.error_creating_single_workout",
        defaultMessage: "Error creating single workout"
    },
    error_copying_workout: {
        id: "messages.error_copying_workout",
        defaultMessage: "Error copying workout"
    },
    error_copying_workout_to_other_client: {
        id: "messages.error_copying_workout_to_other_client",
        defaultMessage: "Error copying workout to other client"
    },
    error_deleting_program: {
        id: "messages.error_deleting_program",
        defaultMessage: "Error deleting program"
    },
    error_adding_program: {
        id: "messages.error_adding_program",
        defaultMessage: "Error adding program"
    },
    error_loading_users: {
        id: "messages.error_loading_users",
        defaultMessage: "Error loading users"
    },
    error_adding_document: {
        id: "messages.error_adding_document",
        defaultMessage: "Error adding document"
    },
    success_adding_document:{
        id:"messages.success_adding_documents",
        defaultMessage:"Successfully added documents"
    },
    success_updating_document:{
        id:"messages.success_updating_documents",
        defaultMessage:"Successfully updated documents"
    },
    success_deleting_document:{
        id:"messages.success_deleting_documents",
        defaultMessage:"Successfully deleted documents"
    },
    errror_deleting_document:{
        id:"messages.error_deleting_document",
        defaultMessage:"Error deleting documents"
    },
    error_updating_document:{
        id:"messages.error_updating_document",
        defaultMessage:"Error while updating documents"   
    },
    disable_rebrand: {
        id: "button.disable_rebrand",
        defaultMessage: "Cancel Rebrand"
    },
    enable_rebrand: {
        id: "button.enable_rebrand",
        defaultMessage: "Enable Rebrand"
    },
    success_accept_wl_client:{
        id:"messages.success_accept_wl_client",
        defaultMessage:"Successfully accepted client request"
    },
    success_reject_wl_client:{
        id:"messages.success_reject_wl_client",
        defaultMessage:"Successfully rejected client request"
    },
    error_accept_wl_client: {
        id:"messages.error_accept_wl_client",
        defaultMessage:"Error while accepting client request"  
    },
    error_reject_wl_client: {
        id:"messages.error_reject_wl_client",
        defaultMessage:"Error while rejecting client request"  
    },
    success_save_workout_to_library:{
        id:"messages.success_save_workout_to_library",
        defaultMessage:"Successfully saved workout to library"
    },
    erorr_save_workout_to_library: {
        id: "messages.erorr_save_workout_to_library",
        defaultMessage: "Error while saving workout to library"
    },
    error_loading_notifications: {
        id: "messages.error_loading_notifications",
        defaultMessage: "Error loading notifications"
    },

})

export default messages;
