import React from "react";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useUserProvider } from "./UserProvider";
import { NotificationWithClientData, subscribeToCountUnreadNotifications, updateNotificationAsRead } from "src/api/notificationApi";
import useFetchAllNotifications from "src/hooks/notifications/useFetchAllNotifications";

export const NotificationContext = createContext<{
  notifications: NotificationWithClientData[];
  isLoading: boolean;
  isLoadingMore: boolean;
  numberOfUnreadNotifications: number;
  markNotificationsAsRead: () => void;
  loadMoreNotifications: () => void;
  hasMore: boolean;
  updateNotificationsList: () => void;
  updateUnlinkNotificationAction: (activityId: string|undefined, type: 'accepted'|'rejected') => void;
  refetch: () => void;
} | undefined>(undefined);


export const NotificationProviderTwo =  ({ children }: PropsWithChildren<any>) => {

    const {user} = useUserProvider();
    const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState<number>(0);
    const [readNotificationsIds, setReadNotificationsIds] = useState<Set<string>>(new Set<string>());
    const {notifications, isLoading, isLoadingMore, fetchMoreNotifications, hasMore, updateReadNotificationsList, updateUnlinkNotification, fetchAllNotificationsQuery:refetch} = useFetchAllNotifications({userId: user?.id});
    
    useEffect(() => {
      if (user && user.id) {
        
        const unsubscribe = subscribeToCountUnreadNotifications({userId: user.id, onUnreadCountChange: setNumberOfUnreadNotifications});
        return () => unsubscribe();
      } else return undefined;
    }, [user?.id]);

    const loadMoreNotifications = () => {
      fetchMoreNotifications();
    }
  
    const markNotificationsAsRead = async () => {
      try {
        if (numberOfUnreadNotifications > 0) {
          const readNotificationsId: Set<string> = new Set(
            notifications
              .filter((notif) => !notif.readTrainer && notif.id !== undefined)
              .map((notif) => notif.id as string) 
          );
          const success = await updateNotificationAsRead({notificationIds:readNotificationsId});
          if(success) setReadNotificationsIds(readNotificationsId);
        }
      } catch(error){
        console.error("Error marking notifications as read:", error);
      }
    };

    const updateNotificationsList = () => {
      if(readNotificationsIds.size > 0) {
        updateReadNotificationsList(readNotificationsIds);
      }
    }

    const updateUnlinkNotificationAction = (activityId: string|undefined, type: 'accepted'|'rejected') => {
      if(activityId) {
        updateUnlinkNotification(activityId, type);
      }
    }
  
    return (
      <NotificationContext.Provider
        value={{
          notifications,
          isLoading,
          isLoadingMore,
          numberOfUnreadNotifications,
          markNotificationsAsRead,
          loadMoreNotifications,
          hasMore,
          updateNotificationsList,
          updateUnlinkNotificationAction,
          refetch
        }}
      >
      {children}
      </NotificationContext.Provider>
  
    );
  
    
  };


export const useNotificationsProviderTwo = () => {
    const context = useContext(NotificationContext);
    if (context === undefined) {
      throw new Error(
        'useNotificationsContext must be used within a NotificationsProvider'
      );
    }
    return context;
};