import React from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { Organization, OrganizationUser } from "myfitworld-model";
import Role from "myfitworld-model/dist/enums/Role";
import { useMfwThemeProvider } from "myfitworld-utils";
import { useStoreState } from "pullstate";
import { Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import LoadingSpinner from "../../components/LoadingSpinner";
import SelectField from "../../components/SelectField";
import SimpleMenu from "../../components/SimpleMenu";
import ProfileImage from "../../components/avatars/ProfileImage";
import ChatButton from "../../components/chat/ChatButton";
import {UsersTableProps, getOptionsWithTranslation, DateTimeOptions} from "../../components/users/helpers";
import globalState from "../../globalState";
import useOrganizationMembers from "../../hooks/Organizations/useOrganizationMembers";
import globalMessages from '../../messages';
import organizationMessages from './messages';
import { useMessagesContext } from "../../providers/MessagesProvider";
import { useUserProvider } from "../../providers/UserProvider";
import useTableStyles from "../../theme/useTableStyles";
import { getUserFullName } from "../../utils/getUserFullName";
import { getUserInitials } from "../../utils/getUserInitials";
import safeInvoke from "../../utils/safeInvoke";


const MembersTable = ({selectedTab, roles, onClick, showRole}: UsersTableProps) => {
   
  // universal consts:
    const theme = useMfwThemeProvider();
    const classes = useTableStyles();
    const {formatMessage} = useIntl();
    const messageCtx = useMessagesContext();
    // const {notificationsForEachClient} = useNotificationsProvider();

    // Current organization and logged user:
    const currentOrganization: Organization | undefined = useStoreState(globalState, s => s.currentOrganization);
    const {user: authUser} = useUserProvider();
    
    const role :Role | undefined= useStoreState(globalState, s => s.currentRole);
    
    // We have 3 filters: by name, last sign in and by trainer
    const {control, watch} = useForm({
      defaultValues: {
        nameSearchFilter:"",
        dateTimeFilter: 'ALL',
      }
    });
    const nameSearchFilterWatcher : string = watch("nameSearchFilter")
    const dateTimeFilterWatcher: string = watch("dateTimeFilter");
    const dateTimeOptions = getOptionsWithTranslation(DateTimeOptions, {formatMessage});
    
    const {organizationMembers: allMembers, isLoadingMembers: isLoadingMembers, updateOrganizationMember,updateOrganizationCopyOkUser} = useOrganizationMembers({
        organizationId: authUser?.currentOrganization,
        isArchived: (selectedTab === 2 || selectedTab === 3) ? true : false,
        isAdmin:(selectedTab === 0 || selectedTab === 2) ? true : false,
        nameSearchQuery: nameSearchFilterWatcher,
        dateTimeQuery: dateTimeFilterWatcher,
    });

    // toggle functions:
    const toggleArchive = async (userData: OrganizationUser) => {
      const archived = !userData.archived;
      if(userData && userData.userId && userData.user && userData.user.organizations){
        updateOrganizationMember({
          userId: userData.userId,
          organizations: userData.user.organizations?.map(
            (orgUser) => {
              return orgUser.id === userData.orgId && orgUser.role === userData.role
                ? {...orgUser, archived}
                : orgUser;
            }
          )
        })
      }
    };

    const toggleAllowedToAddNewClients = async (userData: OrganizationUser) => {
      const role = userData.role === Role.Trainer ? Role.AssistantTrainer : Role.Trainer;
      if(userData && userData.userId && userData.user && userData.user.organizations){
        updateOrganizationMember({
          userId: userData.userId,
          organizations: userData.user.organizations.map(
            (orgUser) => {
              return orgUser.id === userData.orgId && orgUser.role === userData.role
                ? {...orgUser, role}
                : orgUser;
            }
          )
        });
      }
    };

    const toggleAllowedToCopyContent = async (userData: OrganizationUser) => {
      if(userData && userData.userId){
        let copyOk:boolean = (userData.copyOk!== undefined && userData.copyOk === false) ? true: false
        updateOrganizationCopyOkUser({userId: userData.userId, copyOk: copyOk});
      }
    };

    
// @ts-ignore
  const disableArchiveForFirstAdmin = currentOrganization && currentOrganization.admins[0];
  function isArchiveDisabled(orgUser: OrganizationUser){
    if(!!(authUser && authUser.id === orgUser.userId) || disableArchiveForFirstAdmin === orgUser.userId){
      return true
    } else return false;
  }

  const getNoClientsMessage = () => {
    if(selectedTab === 0) {
      return formatMessage(organizationMessages.no_admins);
    } else if(selectedTab === 1) {
      return formatMessage(organizationMessages.no_trainers);
    } else if (selectedTab === 2) {
      return formatMessage(organizationMessages.no_archived_admins);
    } else { //selectedTab === 3
      return formatMessage(organizationMessages.no_archived_trainers);
    }
  }

  return (
    <Fragment>
      {isLoadingMembers ? <LoadingSpinner/> : (
        <Box>
          <Grid item md={4} xs={12}>
            <Controller
              name={"nameSearchFilter"}
              control={control}
              render={({onChange, value}) => {
                return (
                  <TextField
                    placeholder={formatMessage(globalMessages.search_placeholder)}
                    value={value}
                    onChange={(val) => onChange(val)}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          
          {allMembers.length > 0 ?
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell align="center">{formatMessage(globalMessages.number)}</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="left">{formatMessage(globalMessages.name)}</TableCell>
                    <TableCell align="left">{formatMessage(globalMessages.email)}</TableCell>
                    <TableCell align="right">
                      <Controller
                        name={"dateTimeFilter"}
                        control={control}
                        render={({onChange, value}) => {
                          return (
                            <SelectField
                              label={formatMessage(globalMessages.last_sign_in_options_filter)}
                              options={dateTimeOptions}
                              value={value}
                              onChange={(val) => onChange(val)}
                            />
                          );
                        }}
                      />
                    </TableCell>
                    {role === Role.SuperAdmin && selectedTab === 1 &&
                      <TableCell align="left">{formatMessage(globalMessages.delete_client)}</TableCell>
                    }
                    <TableCell align="left">{formatMessage(globalMessages.clients)}</TableCell>
                    {(roles.includes(Role.Trainer)) && <TableCell align="left">{formatMessage(globalMessages.can_add_new_clients)}</TableCell>}
                    {(roles.includes(Role.Trainer)) &&<TableCell align="left">{formatMessage(globalMessages.copy_org_content)}</TableCell>}
                    {!roles.includes(Role.Client) && !roles.includes(Role.SuperAdmin) &&
                      <TableCell align="left">{formatMessage(globalMessages.actions)}</TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allMembers && allMembers.map((orgUser, index) => {
                    const {user} = orgUser;
                    let unreadMessages = 0;
                    if(authUser !== undefined && authUser !== null && orgUser.userId && messageCtx.messagesForEachClient && Object.keys(messageCtx.messagesForEachClient).length !== 0 && orgUser.userId !== authUser.id){
                      const unreadMessageCtx = messageCtx.messagesForEachClient[orgUser.userId]!== undefined ? messageCtx.messagesForEachClient[orgUser.userId].unread : 0;
                      unreadMessages= unreadMessageCtx;
                    }

                    return (
                      <TableRow key={orgUser.id} className={classes.tableRow}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                        </TableCell>
                        <TableCell align="center"> <Box ml={1}>
                          {!showRole && orgUser.userId !== authUser?.id &&
                            <ChatButton
                              buttonStyle={{padding: theme.mfwMuiWebTheme.spacing(3)}}
                              iconStyle={{fontSize: theme.mfwMuiWebTheme.spacing(4), fill: theme.theme.theme.primary }}
                              userId={orgUser.userId}
                              notifCountIndex={index}
                              badgeNumber={unreadMessages}
                              userName={getUserFullName(user?.lastName,user?.firstName)}
                            />
                          }
                        </Box></TableCell>
                        <TableCell align="right">
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <ProfileImage
                              src={user?.avatarURL}
                              initials={getUserInitials(user?.firstName, user?.lastName)}
                              fontSize={theme.mfwMuiWebTheme.spacing(2.5)}
                              size={theme.mfwMuiWebTheme.spacing(4)}
                              mirror={!!roles.find(e => e === Role.Client) && user?.avatarURL?.startsWith("https://firebasestorage.googleapis.com/")}
                            />
                            <Box ml={2}>
                              <Typography variant="h6">{getUserFullName(user?.lastName, user?.firstName)}</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="left">{user?.email || ""}</TableCell>
                        <TableCell align="right">
                          {
                            user?.onlineStateLastChanged && safeInvoke('toDate')(user.onlineStateLastChanged) &&
                            formatDistanceToNow(safeInvoke('toDate')(user.onlineStateLastChanged), {addSuffix: true})
                          }
                        </TableCell>
                        <TableCell align="center">
                          {user?.clients?.length || 0}
                        </TableCell>
                        {roles.includes(Role.Trainer) && (
                          <TableCell align="center">
                            <Switch
                              color="primary"
                              checked={orgUser.role === Role.Trainer}
                              onChange={() => {toggleAllowedToAddNewClients(orgUser);}}
                              disabled={!!(authUser && authUser.id === orgUser.userId) || orgUser.archived}
                            />
                            <Typography style={{display: 'inline-flex'}}>
                              {orgUser.role !== Role.AssistantTrainer ?
                                formatMessage(globalMessages.yes) : formatMessage(globalMessages.no)}
                            </Typography>
                          </TableCell>
                        )}
                        {roles.includes(Role.Trainer) && (
                          <TableCell align="center">
                            <Switch
                              color="primary"
                              checked={orgUser.copyOk === true}
                              onChange={() => {toggleAllowedToCopyContent(orgUser);}}
                              disabled={!!(authUser && authUser.id === orgUser.userId) || orgUser.archived}
                            />
                            <Typography style={{display: 'inline-flex'}}>
                              {orgUser.copyOk === true  ?
                                formatMessage(globalMessages.yes) : formatMessage(globalMessages.no)}
                            </Typography>
                          </TableCell>
                        )}

                        <TableCell align="left">
                          <SimpleMenu
                            options={[
                              orgUser.archived ?
                                {label: formatMessage(globalMessages.unarchive), action: 'UNARCHIVE'} :
                                {label: formatMessage(globalMessages.archive), action: 'ARCHIVE'}
                            ]}
                            onSelect={(action?: string) => {
                              if (action === "ARCHIVE" || action === "UNARCHIVE") {
                                toggleArchive(orgUser);
                              }
                            }}
                            disabled={isArchiveDisabled(orgUser)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            :
            <Box display='flex' justifyContent='center' alignItems='center' marginTop={7}>
              <Typography variant='h5' color='primary'>{getNoClientsMessage()}</Typography>
            </Box>
          }
        </Box>
      )}
    </Fragment>
    )
  };
export default MembersTable;
