import React, {CSSProperties, Fragment, useCallback, useState, lazy, Suspense} from "react";
import MessageIcon from '@material-ui/icons/Message';
import {Badge, IconButton} from "@material-ui/core";
import LoadingSpinner from "../LoadingSpinner";

const ChatDialog = lazy(() => import("./ChatDialog"));

const ChatButton = ({userId, buttonStyle, iconStyle, handleChatButtonDialogClose, notifCountIndex, badgeNumber, userName}: Props) => {
  const [isOpen, setOpen] = useState(false);

  const handleDialogOpen = useCallback((event: any) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleDialogClose = useCallback((event: any) => {
    event.stopPropagation();
    setOpen(false);

    handleChatButtonDialogClose &&
    userId &&
    notifCountIndex &&
    handleChatButtonDialogClose(notifCountIndex, userId)
  }, []);
  return (
    <Fragment>
      <IconButton onClick={handleDialogOpen} style={{...(buttonStyle || null)}}>
      <Badge anchorOrigin={{horizontal: 'left', vertical: 'top'}} overlap={"rectangular"} badgeContent={badgeNumber} color="error">
        <MessageIcon style={{...(iconStyle || null)}} />
      </Badge>
      </IconButton>
      {userId && isOpen && (
        <Suspense fallback={<LoadingSpinner />}>
          <ChatDialog 
            isOpen={isOpen} 
            handleClose={handleDialogClose} 
            userId={userId} 
            userName={userName} 
            ignoreTabs={false}
          />
        </Suspense>
      )}
    </Fragment>
  );
};
export default ChatButton;

interface Props {
  userId?: string;
  buttonStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  handleChatButtonDialogClose?: (index:number,userId:string)=>void;
  notifCountIndex?:number;
  badgeNumber?:number;
  userName?: string;
}
