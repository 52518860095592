import { Activity } from "myfitworld-model";
import { firestore } from "../firebase";

export const fetchTrainerNotifications = async ({userId, trainerId, startDate, endDate}:{
    userId: string;
    trainerId: string;
    startDate: Date; 
    endDate: Date; 
  }) => {
    try{
      const query = await firestore.collection("activity")
        .where("type", "==", "TRAINER_NOTIFICATION")
        .where("threadId", "==", userId)
        .where("createdAt", "<", endDate)
        .where("createdAt", ">=", startDate);
        const trainerNotificationsDocs = await query.get();
        const trainerNotifications = trainerNotificationsDocs.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            createdAt:
            data.createdAt && data.createdAt.toDate ? data.createdAt.toDate().toJSON() : new Date(data.createdAt).toJSON(),
          } as Activity;
        });
        return trainerNotifications;
    } catch (error) {
      throw error;
    }
  }


  export const deleteTrainerNotificationForClient = async(userId: string) => {
      firestore
        .collectionGroup('activity')
        .where("type", "==", "TRAINER_NOTIFICATION")
        .where('threadId', '==', userId)
        .get()
        .then((notificationsQuery) => {
  
          notificationsQuery.docs.forEach(doc => {
            doc.ref.delete();
          });
      })
      .catch(error => {
          console.error(error.message);
      });
  }