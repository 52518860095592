import React from "react";
import { Avatar, Box, makeStyles, Typography } from "@material-ui/core";
import { Activity, User } from "myfitworld-model";
import { format } from "date-fns";

export const useNotificationStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: '12px',
    marginBottom: '8px',
    borderRadius: '8px',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '&.unread': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
  },
  userInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 32,
    height: 32,
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  userName: {
    fontSize: '15px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  typeLabel: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    flexGrow: 1, 
    textAlign: 'left',
  },
  content: {
    marginLeft: '44px',
    marginTop: theme.spacing(0.5),
  },
  actions: {
    marginLeft: '44px',
    marginTop: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  dateText: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  actionButton: {
    fontSize: '13px',
    textTransform: 'none',
    padding: theme.spacing(0.5, 1),
    minWidth: 'auto',
  },
  headerIconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,  // Prevents shrinking
    minWidth: 'fit-content',  // Ensures it only takes necessary space
    gap: theme.spacing(0.5),
  },
  
}));

interface BaseNotificationViewProps {
  activity: Activity;
  client?: User;
  headerIcon?: React.ReactNode;
  headerLabel?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

export const BaseNotificationView: React.FC<BaseNotificationViewProps> = ({
  activity,
  client,
  headerIcon,
  headerLabel,
  children,
  actions
}) => {
  const classes = useNotificationStyles();

  return (
    <Box className={`${classes.container} ${!activity.readTrainer ? 'unread' : ''}`}>
      <Box className={classes.header}>
        <Avatar className={classes.avatar} src={client?.avatarURL} />
        <Box className={classes.userInfo}>
          <Box className={classes.headerContent}>
            <Typography component="span" className={classes.userName}>
              {client?.firstName} {client?.lastName}
            </Typography>
            <Box className={classes.headerIconContainer}>
                {headerIcon} 
                {headerLabel && (
                  <Typography component="span" className={classes.typeLabel}>
                      &nbsp;{headerLabel}
                  </Typography>
                )}
            </Box>
          </Box>
          <Typography className={classes.dateText}>
            {format(activity.createdAt, "yyyy-MM-dd HH:mm")}
          </Typography>
        </Box>
      </Box>
      
      {children && (
        <Box className={classes.content}>
          {children}
        </Box>
      )}

      {actions && (
        <Box className={classes.actions}>
          {actions}
        </Box>
      )}
    </Box>
  );
};