import firebase from "firebase";
import { Activity, User } from "myfitworld-model";
import { firestore } from "src/firebase";
import { getUser } from "./usersApi";
const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;
const ACTIVITY_COLLECTION = "activity"

export const setNotificationsToBeActive = (messagingTopic:string, messagingToken:string) : Promise<any> =>{
    if(messagingTopic === ""){return new Promise<null>(()=>null);}
    const rSendObj = {
        topic : messagingTopic,
        token : messagingToken
    };
    //messagingTopic represents current User ID
    // messagingToken represents current browser client
    return new Promise<any>(async resolve => {
        const token = await firebase.auth().currentUser?.getIdToken();
        await fetch(`${FUNCTIONS_BASE_URL}/webTrainerNotifications/subscribe`, {
            method: 'POST',
            body: JSON.stringify(rSendObj),
            headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? 'Bearer ' + token : ''
            }
        });
        return true;
    })

}

export const setNotificationsToBeInactive = (messagingTopic:string, messagingToken:string) : Promise<null> =>{
    if(messagingTopic === ""){return new Promise<null>(()=>null);}
    const rSendObj = {
        topic : messagingTopic,
        token : messagingToken
    };
    //messagingTopic represents current User ID
    // messagingToken represents current browser client
    return new Promise<any>(async resolve => {
        const token = await firebase.auth().currentUser?.getIdToken();
        await fetch(`${FUNCTIONS_BASE_URL}/webTrainerNotifications/unsubscribe`, {
            method: 'POST',
            body: JSON.stringify(rSendObj),
            headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? 'Bearer ' + token : ''
            }
        });
        return true;
    })
}


export const subscribeToCountUnreadNotifications = (
    { userId, onUnreadCountChange }: { userId: string; onUnreadCountChange: (count: number) => void }
  ) => {
    return firestore.collection(ACTIVITY_COLLECTION)
      .where('watchers', 'array-contains', userId)
      .where('readTrainer', '==', false)
      .where('isSent', '==', true)
      .onSnapshot((snap) => {
        if (!snap.empty) {
          onUnreadCountChange(snap.docs.length);
        } else {
          onUnreadCountChange(0); 
        }
      });
};


export const fetchAllNotifications = async ({
  userId,
  limit,
  startAfter,
}: {
  userId: string;
  limit: number;
  startAfter?: any; // Firestore document snapshot or field value
}) => {
  try {
    let query = firestore
      .collection("activity")
      .where("watchers", "array-contains", userId)
      .where("type", "in", [
        "BIRTHDAY_NOTIFICATION",
        "WORKOUT_ACTIVITY",
        "TRAINER_ACTIVITY",
        "DELETE_USER",
        "TRAINER_NOTIFICATION",
        "UNLINK_QUESTION",
        "UNLINK_NOTIFICATION",
      ])
      .where("isSent", "==", true)
      .where("createdAt", "<=", new Date())
      .orderBy("createdAt", "desc");

      if(startAfter){
        const lastDocSnap = await firestore.collection("activity").doc(startAfter).get();
        if (lastDocSnap.exists) {
            query = query.startAfter(lastDocSnap);
        }
      }
      query = query.limit(limit);
    

      const snap = await query.get();
      const allNotifications = snap.docs.map((doc) => formatActivity(doc));
      return getNotificationsWithClientData(allNotifications);

  } catch (error){
    console.log(error);
    throw error;
  }
}

export const updateNotificationAsRead = async ({notificationIds} : {notificationIds: Set<string>}): Promise<boolean> => {
  try {
    const batch = firestore.batch();
    notificationIds.forEach((id) => {
        const docRef = firestore.collection("activity").doc(id);
        batch.update(docRef, { readTrainer: true });
    });

    await batch.commit();
    return true;

  } catch (error) {
    console.error("Error updating notifications as read:", error);
    throw error;
  }
};
  
const formatActivity = (doc: firebase.firestore.DocumentData) => {
    return {
      ...doc.data(),
      createdAt: new Date(doc.data().createdAt !== undefined ? doc.data().createdAt.seconds * 1000 : doc.data().message.createdAt.seconds * 1000),
      id: doc.id,
      readTrainer: doc.data().readTrainer
    } as Activity;
};

 const getNotificationsWithClientData = async (notifications: Activity[]) => {
    if (!notifications?.length) return [];
    try {
      const uniqueClientIds = [...new Set(notifications.map(n => n.threadId))];
      const clientsPromises = uniqueClientIds.map(id => getUser(id));
      const clients = await Promise.all(clientsPromises);
      
      const clientMap = clients.reduce<Record<string, User>>((acc, client) => {
        if (client?.id) acc[client.id] = client;
        return acc;
      }, {});
      
        const notificationsWithClientData = notifications.map(notification => {
            return {
                ...notification,
                client: clientMap[notification.threadId]
            } 
        }) as NotificationWithClientData[];
        return notificationsWithClientData ?? [];
    } catch (error) {
      console.error("Error loading clients:", error);
      throw error;
    } 
}

export interface NotificationWithClientData extends Activity{
    client: User;
}