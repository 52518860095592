import { useState, useEffect } from 'react';
import {checkIsArchivedUser, getCurrentUserRole, getNumberOfAdminsTrainers} from "../api/usersApi";
import {Role, User} from "myfitworld-model";

const useAdminTrainerCountsAndRoles = (selectedOrganizationId: string, user: User | null): UseAdminTrainerCountsAndRolesProps => {
  const [sumAdminsTrainers, setSumAdminsTrainers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  
  const isMultipleTrainers = sumAdminsTrainers > 1;

  const isSuperAdmin = user && getCurrentUserRole(user) === Role.SuperAdmin;
  const isAdmin = user && getCurrentUserRole(user) === Role.Admin;
  const isAssistantTrainer = user && getCurrentUserRole(user) === Role.AssistantTrainer;
  const isTrainer = user && getCurrentUserRole(user) === Role.Trainer;
  const isMFWOrganization = user && user.currentOrganization === process.env.REACT_APP_MFW_ORGANIZATION_ID;
  const isClient = user && getCurrentUserRole(user) === Role.Client;
  const isArchivedUser = user && checkIsArchivedUser(user);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const admins = getNumberOfAdminsTrainers(selectedOrganizationId, Role.Admin);
      const assistantTrainers = getNumberOfAdminsTrainers(selectedOrganizationId, Role.AssistantTrainer);
      const trainers = getNumberOfAdminsTrainers(selectedOrganizationId, Role.Trainer);

      Promise.allSettled([admins, assistantTrainers, trainers])
        .then((results) => {
          const sumValues = results.reduce((acc, result) => {
            if (result.status === 'fulfilled') {
              return acc + result.value;
            } else {
              return acc;
            }
          }, 0);

          setSumAdminsTrainers(sumValues);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (selectedOrganizationId && user) {
        fetchUserData();
    } else {
        setIsLoading(false);
    }

  }, [selectedOrganizationId, user]);

  return {
    sumAdminsTrainers,
    isMultipleTrainers,
    isSuperAdmin,
    isAdmin,
    isTrainer,
    isAssistantTrainer,
    isClient,
    isMFWOrganization,
    isArchivedUser,
    isLoading,
  };
};

export default useAdminTrainerCountsAndRoles;


interface UseAdminTrainerCountsAndRolesProps {
  sumAdminsTrainers: number;
  isMultipleTrainers: boolean;
  isSuperAdmin: boolean|null;
  isAdmin: boolean|null;
  isTrainer: boolean|null;
  isAssistantTrainer: boolean|null;
  isClient: boolean|null;
  isMFWOrganization: boolean|null;
  isArchivedUser: boolean|null|undefined;
  isLoading: boolean;
}
