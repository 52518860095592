import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  daily_activity_title: {
    id: 'notification.daily_activity_title',
    defaultMessage: 'Daily Activity'
  },
  workout_completed: {
    id: 'notification.workout_completed',
    defaultMessage: 'Workout Completed'
  },
  notification_details_label: {
    id: 'notification.details_label',
    defaultMessage: 'View Details'
  },
  birthday_notification_title: {
    id: 'notification.birthday_notification',
    defaultMessage: 'BIRTHDAY'
  },
  client_birthday_today: {
    id: 'notification.client_birthday_today',
    defaultMessage: "Today is the client's birthday"
  },
  birthday_greeting_card: {
    id: 'notification.birthday_greeting_card',
    defaultMessage: 'SEND A BIRTHDAY GREETING CARD'
  },
  chat_type_message: {
    id: 'chat.type_message',
    defaultMessage: 'Type a message...'
  },
  load_more: {
    id: 'chat.load_more',
    defaultMessage: 'Load more'
  },
  not_have_notifications_for_selected_user: {
    id: 'notifications.not_have_notifications_for_selected_user',
    defaultMessage: "You don't have notifications for selected user."
  },
  user_with_email: {
    id: 'client.client_delete_form.user_with_email',
    defaultMessage: 'User with email'
  },
  has_deleted_account: {
    id: 'client.client_delete_form.has_deleted_account',
    defaultMessage: 'has deleted account.'
  },
  trainer_notification: {
    id: 'chat.trainer_notification',
    defaultMessage: 'Trainer notification'
  },
  archive_chat: {
    id: 'button.archive_chat',
    defaultMessage: 'Archive Chat'
  },
  unarchive_chat: {
    id: 'button.unarchive_chat',
    defaultMessage: 'Unrchive Chat'
  },
  delete_chat: {
    id: 'button.delete_chat',
    defaultMessage: 'Delete Chat'
  },
  archive_chat_questions: {
    id: 'chat.archive_chat_questions',
    defaultMessage: 'Are you sure you want to archive this chat? '
  },
  unarchive_chat_questions: {
    id: 'chat.unarchive_chat_questions',
    defaultMessage: 'Are you sure you want to unarchive this chat? '
  },
  delete_chat_questions: {
    id: 'chat.delete_chat_questions',
    defaultMessage: 'Are you sure you want to delete this chat? '
  },
  ask_keep_workouts: {
    id: 'chat.ask_keep_workouts',
    defaultMessage: 'unlinked from your organization and asked to keep workouts and statistic.'
  },
  accept: {
    id: 'invitation.info_page.button_accept',
    defaultMessage: 'Accept'
  },
  reject: {
    id: 'reject',
    defaultMessage: 'Reject'
  },
  rejected: {
    id: 'rejected',
    defaultMessage: 'REJECTED'
  },
  accepted: {
    id: 'accepted',
    defaultMessage: 'ACCEPTED'
  },
  reply: {
    id: 'chat.reply',
    defaultMessage: 'Reply'
  },
  unlinked_user: {
    id: 'chat.unlinked_user',
    defaultMessage: 'unlinked from your organization.'
  },
})
export default messages;
