import React from "react";
import {Activity, User} from "myfitworld-model";
import { Button, Typography} from "@material-ui/core";
import { CakeOutlined } from "@material-ui/icons";
import { useIntl } from "react-intl";
import chatMessages from "./messages";
import { BaseNotificationView } from "../notifications/BaseNotificationView";

interface BirthdayNotificationViewProps {
  activity: Activity;
  onSendHappyBirthday: () => void;
  client?: User;
}

const BirthdayNotificationView: React.FC<BirthdayNotificationViewProps> = ({
  activity,
  onSendHappyBirthday,
  client
}) => {
  const { formatMessage } = useIntl();

  const actions = (
    <Button 
      variant="text" 
      color="primary" 
      onClick={onSendHappyBirthday}
    >
      {formatMessage(chatMessages.birthday_greeting_card)}
    </Button>
  );
  return (
    <BaseNotificationView
      activity={activity}
      client={client}
      headerIcon={<CakeOutlined style={{width: 17, height: 17}}/>}
      headerLabel={formatMessage(chatMessages.birthday_notification_title)}
      actions={actions}
    >
      <Typography>
        {formatMessage(chatMessages.client_birthday_today)}
      </Typography>
    </BaseNotificationView>
  );
};
export default BirthdayNotificationView;