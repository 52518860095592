export const getMetricUnitForNutritionValues = (name: string) => {
    //default; kcal, g
    return name === 'energeticValue' ? 'kJ' : 'g';
}
function roundAndFormatNumber(number: number, decimalPlaces:number) {
    const roundedNumber = Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    return roundedNumber.toLocaleString('en-US', { maximumFractionDigits: decimalPlaces });
  }

export const transformGrToOz = (grValue: number)=>{
    return +roundAndFormatNumber(+(0.035 * grValue).toFixed(2), 1);
}

export const transformOzToGr = (ozValue: number)=>{
    return Math.round(+(28.35 * ozValue));
}

export const transformMlToOz = (mlValue: number)=>{
    return +roundAndFormatNumber(+(0.0353* mlValue).toFixed(2), 1);
}
export const transformOzToMl = (ozValue: number)=>{
    return Math.round(+(28.35* ozValue));
}

export const getImperialUnitForNutritionValues = (name: string) => {
    return name === 'energeticValue' ? 'kcal' : 'oz';
}

  //uvek se racuna naspram metricUnit
export const getImperialValue = (metricValue: number, unit: string) => {
    switch(unit){
        case "kJ": //kcal to kJ
            return Math.round(0.239 * metricValue);
        case "g": //g to oz
            return +(0.035 * metricValue).toFixed(2);
        default: //mg to oz
            return +(0.000035 * metricValue).toFixed(2);
    }
}

export const getMetricValue = (imperialValue: number, unit: string) => {
    switch(unit){
        case "kJ": //kJ to kcal
            return Math.round(4.184 * imperialValue);
        case "g":
            return +parseFloat((28.3495 * imperialValue).toFixed(2));
        default: //mg
            return +parseFloat((28349.5 * imperialValue).toFixed(2));
    }
}


export const getNameOfNutritionValue = (index: number):string => {

    switch(index){
        case 0:
            return 'energeticValue';
        case 1:
            return 'fatt';
        case 2: 
            return 'ofWhichSaturates';
        case 3:
            return 'UH';
        case 4:
            return 'ofWhichSugars';
        case 5:
            return 'protein';
        case 6:
            return 'fibre';
        default:
            return 'salt';
    }
}