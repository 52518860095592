import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const updateUserOnlineStatus = async (
  userId: string | undefined,
  fields: { isOnline?: boolean; onlineStateLastChanged?: firebase.firestore.FieldValue },
  email?: string
) => {
  if (!userId && !email) {
    console.error('Either User ID or Email is required to update the user status');
    return;
  }

  try {
    let userDocRef: firebase.firestore.DocumentReference | undefined;

    if (email) {
      const usersCollection = firebase.firestore().collection('users');
      const querySnapshot = await usersCollection.where('email', '==', email).get();

      if (!querySnapshot.empty) {
        userDocRef = querySnapshot.docs[0].ref;
      } else {
        console.error('No user found with the provided email');
        return;
      }
    } else if (userId) {
      userDocRef = firebase.firestore().collection('users').doc(userId);
    }

    if (userDocRef) {
      await userDocRef.update(fields);
    } else {
      console.error('Invalid user document reference. Update aborted.');
    }
  } catch (error) {
    console.error('Error updating user status:', error);
  }
};

const useOnlineStatusTracker = (userId: string) => {
  const [isOnlineForFirestore, setIsOnlineForFirestore] = useState({
    isOnline: true,
    onlineStateLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
  });

  const [isOfflineForFirestore, setIsOfflineForFirestore] = useState({
    isOnline: false,
    onlineStateLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
  });

  useEffect(() => {
    const handleOnline = () => {
      const updatedOnlineStatus = {
        isOnline: true,
        onlineStateLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
      };
      setIsOnlineForFirestore(updatedOnlineStatus);
      updateUserOnlineStatus(userId, updatedOnlineStatus); // Use exported function
    };

    const handleOffline = () => {
      const updatedOfflineStatus = {
        isOnline: false,
        onlineStateLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
      };
      setIsOfflineForFirestore(updatedOfflineStatus);
      updateUserOnlineStatus(userId, updatedOfflineStatus); // Use exported function
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [userId]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateUserOnlineStatus(userId, {
          isOnline: true,
          onlineStateLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        updateUserOnlineStatus(userId, {
          isOnline: false,
          onlineStateLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [userId]);

  return { isOfflineForFirestore, isOnlineForFirestore };
};

export default useOnlineStatusTracker;
