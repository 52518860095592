import { Invitation } from "myfitworld-model";
import React, { useEffect, useState } from "react";
import { Button, TableCell, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Role from "myfitworld-model/dist/enums/Role";
import { useMfwThemeProvider } from "myfitworld-utils";
import globalMessages from '../../messages';
import invitationMessages from './messages';
import { useIntl } from 'react-intl';
import { getPendingClientStatus, PendingClientStatus } from "src/api/invitation";

interface Props {
  invitation: Invitation,
  sendAgainInvitation: (id: string, email: string, wlOrgId: string, userId: string) => Promise<void>,
}

const InvitationStatusCell = ({ invitation, sendAgainInvitation }: Props) => {
  // Universal props:
  const { formatMessage } = useIntl();
  const theme = useMfwThemeProvider();

  const getConnectionRequestBody = (invitation: Invitation) => {
    if (invitation.isInSendingQueue) {
      return formatMessage(invitationMessages.pending);
    } else if (invitation.invitationSent) {
      return (
        <Alert severity="success">
          {formatMessage(invitationMessages.invitation_sent)}
        </Alert>
      );
    } else {
      return invitation.role === Role.Client ?
        formatMessage(globalMessages.no) :
        formatMessage(invitationMessages.pending);
    }
  }

  async function handleSendAgain(e:any){
    e.stopPropagation();
    if (invitation.id) {
      await sendAgainInvitation(
        invitation.id,
        invitation.email ?? "",
        theme.theme.orgId,
        invitation.userId ?? ""
      );
    }
  }
  const [invitationStatus, setInvitationStatus] = useState<PendingClientStatus | undefined>();

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const status = await getPendingClientStatus(invitation.id || "", invitation.userId || "", invitation.email || "");
        setInvitationStatus(status);
      } catch (error) {
        console.error("Error fetching invitation status:", error);
      }
    };

    fetchStatus();
  }, [invitation.id, invitation.userId]);

  return (
    <TableCell align={"center"}>
      {(() => {
        switch (invitationStatus) {
          case PendingClientStatus.rejected:
            return (
              <>
                <Typography color="error">
                  {formatMessage(invitationMessages.rejected)}
                </Typography>
                <Button
                  color="primary"
                  onClick={async (e) => {await handleSendAgain(e)}}
                  variant="outlined"
                >
                  {formatMessage(invitationMessages.send_again)}
                </Button>
              </>
            );
          case PendingClientStatus.pending:
            return (
              <>
                 {getConnectionRequestBody(invitation)}
              </>
            );
          case PendingClientStatus.error:
            return(
              <>
               <Typography color="error">
                  {formatMessage(globalMessages.error)}
                </Typography>
                <Button
                  color="primary"
                  onClick={async (e) => {await handleSendAgain(e)}}
                  variant="outlined"
                >
                  {formatMessage(invitationMessages.send_again)}
                </Button>
              </>
            )
          default: //If status is undefined or error:
            return (
              <>
               
              </>
            );
        }
      })()}
    </TableCell>
  );
};

export default InvitationStatusCell;