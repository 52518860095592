import { UserMessagePreferences, deletedChatInfo } from "myfitworld-model/src/firestoreCollectionTypes/User";
import { useEffect, useState } from "react";
import { ChatsMap } from "./MessagesProvider";


function useSortChats({
    allChats,
    messagePreferences,
}:{
    allChats: ChatsMap | undefined,
    messagePreferences?: UserMessagePreferences
}) {
    const archivedIds:string[]= messagePreferences?.archivedIds ? messagePreferences.archivedIds : [];
    const deletedChatInfo:deletedChatInfo[] = messagePreferences?.deleted ? messagePreferences.deleted : [];

    const [deletedChats, setDeletedChats] = useState<ChatsMap | undefined>();
    const [archivedChats, setArchivedChats] = useState<ChatsMap | undefined>();
    const [regularChats, setRegularChats] = useState<ChatsMap | undefined>();

    function filterArchivedChats() {
        const filteredChats: ChatsMap = {};
        if (allChats && archivedIds && archivedIds.length > 0) {
            for (const conversationId of archivedIds) {
                if (allChats[conversationId]) {
                    const chat = allChats[conversationId];
                    const deletedMessages = (deletedChats && deletedChats[conversationId]?.messages) || [];
                    const nonDeletedMessages = chat.messages.filter(msg => !deletedMessages.includes(msg));
                    if (nonDeletedMessages.length > 0) {
                        filteredChats[conversationId] = {
                            messages: nonDeletedMessages,
                            unread: chat.unread,
                        };
                    }
                }
            }
        }
        setArchivedChats(filteredChats);
    }
    
    
    function filterDeletedChats() {
        const filteredChats: ChatsMap = {};
        if (allChats && deletedChatInfo && deletedChatInfo.length > 0) {
            for (const info of deletedChatInfo) {
                const chat = allChats[info.id];
                if (chat) {
                    const i:any = info.date;
                    const deletionDate = new Date(i.seconds * 1000 + i.nanoseconds / 1e6);
                    const filteredMessages = chat.messages.filter((msg) => {
                        return msg.createdAt < deletionDate;
                    });

                    if (filteredMessages.length > 0) {
                        filteredChats[info.id] = {
                            messages: filteredMessages,
                            unread: chat.unread,
                        };
                    }
                }
            }
        } 
        setDeletedChats(filteredChats);
    }
    
    function filterRegularChats(archivedChats: ChatsMap | undefined, deletedChats: ChatsMap | undefined) {
        let chats: ChatsMap = {};
        for (const conversationId in allChats) {
            if (archivedChats && !archivedChats[conversationId]) { 
                const chat = allChats[conversationId];
                const deletedMessages = (deletedChats && deletedChats[conversationId]?.messages) || []; 
                const regularMessages = chat.messages.filter(msg => !deletedMessages.includes(msg)); 
                if (regularMessages.length > 0) {
                    chats[conversationId] = {
                        messages: regularMessages,
                        unread: chat.unread,
                    };
                }
            }
        }
        const sortedEntries = Object.entries(chats)
        .sort((a, b) => {
            // Sort by the `createdAt` property of the last message
            return (
            new Date(b[1].messages[b[1].messages.length - 1].createdAt).getTime() -
            new Date(a[1].messages[a[1].messages.length - 1].createdAt).getTime()
            );
        });

        // Convert sorted entries back to an object
        const sortedChats: ChatsMap = Object.fromEntries(sortedEntries);
        setRegularChats(sortedChats);
    }
    

    useEffect(() => {
        if(allChats){
            filterDeletedChats();
        }
    }, [allChats, messagePreferences]);

    useEffect(() => {
        if(allChats){
            filterArchivedChats();
        }
        //archived messages also depends on deleted bc we can't have deleted messages in archived ones
    }, [deletedChats, allChats, messagePreferences]);

    useEffect(()=>{
        filterRegularChats(archivedChats, deletedChats);
    },[archivedChats, deletedChats])
    
    
    return {
       regularChats,
       archivedChats,
       deletedChats
    };
}
export default useSortChats;
