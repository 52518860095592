import React, {Fragment, useState} from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Badge, IconButton} from "@material-ui/core";
import NotificationsDrawer from "./NotificationsDrawer";
import { useMfwThemeProvider } from "myfitworld-utils";
import { useNotificationsProviderTwo } from "src/providers/NotificationsProviderTwo";

const useStyles = makeStyles(() =>
  createStyles({
    notification: {
      color: "white",
    },
  })
);
const NotificationButton = () => {
  const classes = useStyles();
  const theme = useMfwThemeProvider();
  const [isOpen, setOpen] = useState(false);
  const {numberOfUnreadNotifications} = useNotificationsProviderTwo();

  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <Badge badgeContent={numberOfUnreadNotifications} color="error">
          <NotificationsIcon style={{color:theme.mfwMuiWebTheme.palette.text.primary}} className={classes.notification}/>
        </Badge>
      </IconButton>
      {isOpen && <NotificationsDrawer isOpen={isOpen} onClose={() => setOpen(false)}/>}
    </Fragment>
  );
};

export default NotificationButton;
