import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Activity } from "myfitworld-model";
import { useIntl } from "react-intl";
import chatMessages from './messages';
import { BaseNotificationView } from "../notifications/BaseNotificationView";

interface DeleteUserNotificationViewProps {
    activity: Activity;
}

const useStyles = makeStyles((theme) => ({
  messageText: {
    fontSize: '14px',
    color: theme.palette.error.dark,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
}));
  
const DeleteUserNotificationView: React.FC<DeleteUserNotificationViewProps> = ({
    activity
    }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();

    return (
        <BaseNotificationView activity={activity}>
            <Typography color="error" className={classes.messageText}>
                {formatMessage(chatMessages.user_with_email)} {activity.email} {formatMessage(chatMessages.has_deleted_account)}
            </Typography>
        </BaseNotificationView>
    );
};
export default DeleteUserNotificationView;
