import React, {Fragment, memo, useCallback, useEffect, useState} from "react";
import SideDrawer, {SideDrawerProperties} from "../SideDrawer";
import ActivityView from "../chat/ActivityView";
import {Activity, Tutorial, User} from "myfitworld-model";
import ChatDialog from "../chat/ChatDialog";
import {useNavigate} from "@reach/router";
import BirthdayNotificationView from "../chat/BirthdayNotificationView";
import LoadMoreButton from "../chat/LoadMoreButton";
import { Box, Typography } from "@material-ui/core";
import DeleteUserNotificationView from "../chat/DeleteUserNotificationView";
import { buildUrl, configMainRoutesConsts } from "../../config/route";
import TrainerNotificationView from "../chat/TrainerNotificationView";
import { useIntl } from "react-intl";
import notificationMessages from './messages';
import useEntityList from "../../hooks/useEntityList";
import {tutorialApi} from "../../api/common";
import {getVideoSourceByAppSection} from "../../utils/getVideoSourceTutorial";
import {SectionOptions} from "../users/helpers";
import {useUserProvider} from "../../providers/UserProvider";
import LoadingSpinner from "../LoadingSpinner";
import UnlinkQuestionView from "../chat/UnlinkQuestionView";
import useKeepClientWorkouts from "src/hooks/ClientWorkouts/useKeepClientWorkouts";
import { useNotificationsProviderTwo } from "src/providers/NotificationsProviderTwo";

interface DialogState {
  isOpen: boolean;
  userId?: string;
  userName?: string;
  replyActivity?: Activity;
}

interface NotificationItemProps {
  activity: NotificationWithClientData;
  onViewDetails: (activity: Activity) => void;
  onReply: (userId: string, userName: string, activity: Activity) => void;
  client?: User;
  openDialog?: (isOpen: boolean, userId?: string, userName?: string) => void;
}

interface NotificationsListProps {
  notifications: NotificationWithClientData[];
  onViewDetails: (activity: Activity) => void;
  onReply: (userId: string, userName: string, activity: Activity) => void;
  isLoadingMore: boolean;
  showLoadMore?: boolean;
  onLoadMore?: () => void;
  openDialog?: (isOpen: boolean, userId: string|undefined, userName: string|undefined) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = memo(({ activity, onViewDetails, client, onReply, openDialog }) => {
  const { user } = useUserProvider();
  const { confirmKeepWorkouts, rejectKeepWorkouts } = useKeepClientWorkouts();
  const { updateUnlinkNotificationAction } = useNotificationsProviderTwo();
  const [answerType, setAnswerType] = useState<'accepted' | 'rejected' | undefined>();

  const content = (() => {
    switch (activity.type) {
      case "BIRTHDAY_NOTIFICATION":
        return (
            <BirthdayNotificationView
              activity={activity}
              client={activity.client}
              key={activity.id}
              onSendHappyBirthday={() => openDialog && openDialog(true, activity.client?.id, `${activity.client?.firstName} ${activity.client?.lastName}`)}
            />
        );
      case "DELETE_USER":
        return (
            <DeleteUserNotificationView activity={activity} />
        );
      case "TRAINER_NOTIFICATION":
        return (
            <TrainerNotificationView
              activity={activity}
              client={activity.client}
            />
        );
      case "UNLINK_QUESTION":
      case "UNLINK_NOTIFICATION":
        return (
            <UnlinkQuestionView
              activity={activity}
              key={activity.id}
              isNotificationOnly={activity.type === "UNLINK_NOTIFICATION"}
              onConfirm={async () => {
                await confirmKeepWorkouts(activity);
                setAnswerType('accepted');
                updateUnlinkNotificationAction(activity.id, 'accepted');
              }}
              unlinkAnswerType={answerType}
              onReject={async () => {
                if (user?.currentOrganization) {
                  await rejectKeepWorkouts(activity, user.currentOrganization);
                  setAnswerType('rejected');
                  updateUnlinkNotificationAction(activity.id, 'rejected');
                }
              }}
            />
        );
      default:
        return (
            <ActivityView
              activity={activity}
              key={activity.id}
              onViewDetails={() => onViewDetails(activity)}
              client={activity.client}
              onReply={(clientId, clientName, replyActivity) => {
                if (clientId && clientName && replyActivity) onReply(clientId, clientName, replyActivity);
              }}
            />
        );
    }
  })();

  return (
    <Box>
      {content}
    </Box>
  );
});

const NotificationsList: React.FC<NotificationsListProps> = memo(({ 
  notifications, 
  onViewDetails, 
  onReply,
  showLoadMore,
  onLoadMore,
  isLoadingMore,
  openDialog
}) => {
  return (
    <Box 
      sx={{ 
        height: 'calc(100vh - 100px)',
        width: '100%',
        borderRadius: '4px',
        overflow: 'auto',
        padding: '16px'
      }}
    >
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          activity={notification}
          onViewDetails={onViewDetails}
          client={notification.client}
          onReply={onReply}
          openDialog={openDialog}
        />
      ))}
      {showLoadMore && (
        isLoadingMore ? 
        <LoadingSpinner/> 
        :
        <Box sx={{ padding: '10px', textAlign: 'center' }}>
          <LoadMoreButton onPress={onLoadMore!} />
        </Box>
      )}
    </Box>
  );
});

const NotificationsDrawer = ({ isOpen, onClose }: Props) => {
  const {user} = useUserProvider();
  const navigate = useNavigate();
  const {formatMessage} = useIntl();

  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false
  });

  const {
   notifications, isLoading, isLoadingMore, markNotificationsAsRead, loadMoreNotifications, hasMore, updateNotificationsList, refetch
  } = useNotificationsProviderTwo();

  const handleClose = useCallback(() => {
    onClose();
    updateNotificationsList();
  }, [onClose, updateNotificationsList]);

  const handleDialogOpen = useCallback((userId: string, userName: string, activity: Activity) => {
    setDialogState({
      isOpen: true,
      userId,
      userName,
      replyActivity: activity
    });
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialogState({
      isOpen: false
    });
  }, []);

  const onViewDetails = useCallback((activity: Activity) => {
    const route = activity.workoutId
      ? buildUrl(configMainRoutesConsts.clientStatisticPage, [activity.threadId, activity.workoutId])
      : buildUrl(configMainRoutesConsts.clientDailyActivityInfo, [activity.threadId, activity.id || ""]);
    
    navigate(route);
    onClose();
  }, [navigate, onClose]);


  useEffect(() => {
    if (isOpen) {
      markNotificationsAsRead();
      refetch();
    }
  }, [isOpen]);

  const {data: tutorials} = useEntityList<Tutorial>(tutorialApi.list);
  const videoSource = getVideoSourceByAppSection(tutorials, SectionOptions[12].id, user?.language);

  return (
    <Fragment>
      <SideDrawer 
        isOpen={isOpen} 
        onClose={handleClose} 
        title={formatMessage(notificationMessages.notifications)} 
        videoSource={videoSource}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          {isLoading ? (
            <LoadingSpinner marginTop={20} />
          ) : (
            <>
              {/* <Box sx={{ flex: 1, overflow: 'auto' }}> */}
                {(notifications.length === 0) ? (
                  <Typography variant='h3'>
                    {formatMessage(notificationMessages.no_notifications)}
                  </Typography>
                ) : (
                  <NotificationsList 
                    notifications={notifications}
                    onViewDetails={onViewDetails}
                    onReply={handleDialogOpen}
                    showLoadMore={hasMore}
                    onLoadMore={loadMoreNotifications}
                    isLoadingMore={isLoadingMore}
                    openDialog={(isOpen: boolean, userId: string|undefined, userName: string|undefined) => {if(userId && userName) setDialogState({isOpen, userId, userName})}}
                  />
                )}
              {/* </Box> */}
            </>
          )}
        </Box>
      </SideDrawer>
      {dialogState.isOpen && dialogState.userId && dialogState.userName && (
        <ChatDialog 
          isOpen={dialogState.isOpen}
          handleClose={handleDialogClose}
          userId={dialogState.userId}
          userName={dialogState.userName}
          ignoreTabs={true}
          replyActivity={dialogState.replyActivity}
          setReplyActivity={(activity) => {
            setDialogState(prev => ({
              ...prev,
              replyActivity: typeof activity === 'function' ? activity(prev.replyActivity) : activity
            }));
          }}
        />
      )}
    </Fragment>
  );
};

export default NotificationsDrawer;

interface Props extends SideDrawerProperties {}

export interface NotificationWithClientData extends Activity{
  client: User|undefined;
}
