import React, { useState } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { Activity } from "myfitworld-model";
import { useIntl } from "react-intl";
import chatMessages from './messages';
import { BaseNotificationView, useNotificationStyles } from "../notifications/BaseNotificationView";

const useStyles = makeStyles((theme) => ({
  messageText: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  statusText: {
    fontSize: '13px',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  }
}));

interface UnlinkQuestionViewProps {
  activity: Activity;
  isNotificationOnly: boolean;
  onConfirm?: () => void;
  onReject?: () => void;
  unlinkAnswerType?: 'rejected' | 'accepted';
}

const UnlinkQuestionView: React.FC<UnlinkQuestionViewProps> = ({
  activity,
  isNotificationOnly,
  onConfirm,
  onReject,
  unlinkAnswerType
}) => {
  const classes = useStyles();
  const baseClasses = useNotificationStyles();
  const { formatMessage } = useIntl();
  const [showButtons, setShowButtons] = useState(
    isNotificationOnly ? false : !activity.rejected && !activity.accepted
  );

  const messageAnswered =
    (unlinkAnswerType === 'rejected'
      ? formatMessage(chatMessages.rejected)
      : unlinkAnswerType === 'accepted'
        ? formatMessage(chatMessages.accepted)
        : null)
    ||
    ((activity?.rejected)
      ? formatMessage(chatMessages.rejected)
      : (activity?.accepted)
        ? formatMessage(chatMessages.accepted)
        : null);

  const actions = showButtons && !isNotificationOnly ? (
    <>
      <Button
        className={baseClasses.actionButton}
        color="primary"
        onClick={() => {
          onReject?.();
          setShowButtons(false);
        }}
      >
        {formatMessage(chatMessages.reject)}
      </Button>
      <Button
        className={baseClasses.actionButton}
        color="primary"
        onClick={() => {
          onConfirm?.();
          setShowButtons(false);
        }}
      >
        {formatMessage(chatMessages.accept)}
      </Button>
    </>
  ) : null;

  return (
    <BaseNotificationView
      activity={activity}
      actions={actions}
    >
      <Typography className={classes.messageText}>
        {formatMessage(chatMessages.user_with_email)} {activity.email}&nbsp;
        {isNotificationOnly 
          ? formatMessage(chatMessages.unlinked_user) 
          : formatMessage(chatMessages.ask_keep_workouts)
        }
      </Typography>
      {!showButtons && !isNotificationOnly && messageAnswered && (
        <Typography className={classes.statusText} align="right"> 
          {messageAnswered}
        </Typography>
      )}
    </BaseNotificationView>
  );
};

export default UnlinkQuestionView;