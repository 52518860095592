import React from "react";
import { Activity, User } from "myfitworld-model";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { WbSunnyOutlined, Brightness2Outlined } from "@material-ui/icons";
import { useIntl } from "react-intl";
import chatMessages from "./messages";
import { BaseNotificationView, useNotificationStyles } from "../notifications/BaseNotificationView";
import getLocalizedProperty from "../../utils/getLocalizedProperty";
import ReactionImage from "../ReactionImage";

const useStyles = makeStyles((theme) => ({
  messageText: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  workoutTitle: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  activityIcon: {
    width: 17, 
    height: 17,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(0.5),
  },
  arrowIcon: {
    fontSize: '14px'
  }
}));

interface ActivityViewProps {
  activity: Activity;
  client?: User;
  onReply: (clientId: string, clientName: string, activity: Activity) => void;
  onViewDetails: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ActivityView: React.FC<ActivityViewProps> = ({
  activity,
  client,
  onReply,
  onViewDetails,
}) => {
  const classes = useStyles();
  const baseClasses = useNotificationStyles();
  const { formatMessage, locale } = useIntl();
  
  const headerIcon = activity.dailyActivityType ? activity.dailyActivityType === "morning" ? (
    <WbSunnyOutlined className={classes.activityIcon} />
  ) : (
    <Brightness2Outlined className={classes.activityIcon} />
  ) : activity.reaction ? <ReactionImage reaction={activity?.reaction} /> : null

  const actions = (
    <>
      {activity.dailyActivityType ? null : 
        <Button
          className={baseClasses.actionButton}
          color="primary"
          onClick={() => onReply(client?.id || "", `${client?.firstName} ${client?.lastName}`, activity)}
        >
          {formatMessage(chatMessages.reply)}
        </Button>
      }
      <Button
        className={baseClasses.actionButton}
        color="primary"
        onClick={onViewDetails}
      >
        {formatMessage(chatMessages.notification_details_label)}
      </Button>
    </>
  );


  return (
    <BaseNotificationView
      activity={activity}
      client={client}
      headerIcon={headerIcon}
      headerLabel={activity.dailyActivityType ? formatMessage(chatMessages.daily_activity_title) : formatMessage(chatMessages.workout_completed)}
      actions={actions}
    >
      {activity?.workout?.title && (
        <Typography className={classes.workoutTitle}>
          {getLocalizedProperty(locale, activity.workout.title)}
        </Typography>
      )}
      {activity.text && (
        <Typography className={classes.messageText}>
          {activity.text}
        </Typography>
      )}
    </BaseNotificationView>
  );
};
export default ActivityView;